<template>
    <BT-Blades :anchorBlades="[{ bladeName: 'journey-templates' }, { bladeName: 'stats' }]">
        <template v-slot="bladesData">
            <BT-Blade-List
                :bladesData="bladesData"
                bladeName="journey-templates"
                :canClose="false"
                canSearchLocal
                dense
                :itemProperties="['ID','JourneyName','StartLocation','StartLocationID','EndLocation','EndLocationID','MaxDestinations','MaxWeight','MaxPackages']"
                :loadingMsg="loadingMsg"
                navigation="journey-templates"
                :onPullSuccessAsync="pullJourneys"
                :searchProps="['journeyName']"
                title="Journeys">
                <template v-slot:items="{ items }">
                    <v-list dense>
                        <template v-for="(item, ind) in items">
                            <v-list-group :key="ind" :append-icon="null" class="my-0 py-0" no-action dense>
                                <template v-slot:activator>
                                    <v-list-item-content @click="loadJourneyData(item)">
                                        <v-list-item-title>{{ item.journeyName }}</v-list-item-title>
                                        <v-list-item-subtitle v-if="item.startLocationID == item.endLocationID">{{ item.startLocation | toFamiliarLocationLine }}</v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>{{ item.startLocation.locationName }} {{ '-->' }} {{ item.endLocation.locationName }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                                <v-container>
                                    <v-row>
                                        <v-col cols="4" class="text-center">
                                            <v-progress-circular
                                                color="success"
                                                size="85"
                                                :value="item.totalDeliveries">
                                                <div class="text-center success--text">
                                                    <div>{{ item.totalDeliveries }}</div>
                                                    <div>Deliveries</div>
                                                </div>
                                            </v-progress-circular>
                                        </v-col>
                                        <v-col cols="4" class="text-center">
                                            <v-progress-circular
                                                color="warning"
                                                size="85"
                                                :value="item.totalMinutes">
                                                <div class="text-center warning--text">
                                                    <div>{{ Math.round(item.totalMinutes / 60) }}</div>
                                                    <div>Hours</div>
                                                </div>
                                            </v-progress-circular>
                                        </v-col>
                                        <v-col cols="4" class="text-center">
                                            <v-progress-circular
                                                color="danger"
                                                size="85"
                                                :value="Math.round(item.totalWeight / 100)">
                                                <div class="text-center danger--text">
                                                    <div>{{ Math.round(item.totalWeight / 100) }}</div>
                                                    <div>Weight (kg)</div>
                                                </div>
                                            </v-progress-circular>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" class="text-center">
                                            <v-progress-circular
                                                color="success"
                                                size="85"
                                                :value="item.totalDrivers">
                                                <div class="text-center success--text">
                                                    <div>{{ item.totalDrivers }}</div>
                                                    <div>Drivers</div>
                                                </div>
                                            </v-progress-circular>
                                        </v-col>
                                        <v-col cols="4" class="text-center">
                                            <v-progress-circular
                                                color="warning"
                                                size="85"
                                                :value="item.totalTrips">
                                                <div class="text-center warning--text">
                                                    <div>{{ item.totalTrips }}</div>
                                                    <div>Trips</div>
                                                </div>
                                            </v-progress-circular>
                                        </v-col>
                                        <v-col cols="4" class="text-center">
                                            <v-progress-circular
                                                color="danger"
                                                size="85"
                                                :value="Math.round(item.totalVolume / 1000000)">
                                                <div class="text-center danger--text">
                                                    <div>{{ Math.round(item.totalVolume / 1000000) }}</div>
                                                    <div>Volume (m3)</div>
                                                </div>
                                            </v-progress-circular>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-list-group>
                        </template>
                    </v-list>
                </template>
            </BT-Blade-List>
            <BT-Blade
                :bladesData="bladesData"
                bladeName="stats"
                :canClose="false"
                :canPin="false"
                :canMinimize="false"
                :canRefresh="false"
                flexColumn
                :loadingMsg="loadingMsg"
                title="Stats">
                <template v-slot:toolbar-left>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" dense small class="ml-4">
                                {{ currentDate != null ? currentDate : (currentTimeSpan != null ? timeSpans[currentTimeSpan].text : 'Time Span') }}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group v-model="currentTimeSpan">
                                <v-list-item v-for="(timeSpan, index) in timeSpans" :key="index">
                                    <v-list-item-content>{{ timeSpan.text }}</v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                    <v-menu v-if="stepperState == 1" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" dense small class="ml-4">
                                {{ currentDriverMode != null ? driverModes[currentDriverMode].text : 'Time Span' }}
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item-group v-model="currentDriverMode">
                                <v-list-item v-for="(driverMode, index) in driverModes" :key="index">
                                    <v-list-item-content>{{ driverMode.text }}</v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:toolbar-right>
                    <v-tabs v-model="stepperState" right style="max-width: 450px;">
                        <v-tab>
                            <v-icon left>mdi-layers-triple</v-icon>
                            <span>Trips</span>
                        </v-tab>
                        <v-tab>
                            <v-icon left>mdi-account-group</v-icon>
                            <span>Drivers</span>
                        </v-tab>
                        <v-tab>
                            <v-icon left>mdi-truck-fast</v-icon>
                            <span>Deliveries</span>
                        </v-tab>
                    </v-tabs>
                </template>
                <template v-slot:page>
                    <v-tabs-items v-if="currentJourney != null" v-model="stepperState">
                        <v-tab-item>
                            <v-container>
                                <div>Averages</div>
                                <v-row>
                                    <v-col class="text-center">
                                        <v-progress-circular
                                            size="75"
                                            :value="percent(divide(currentJourney.totalDeliveries, currentJourney.totalTrips), currentJourney.maxDestinations)">
                                            <div class="text-center">{{ divide(currentJourney.totalDeliveries, currentJourney.totalTrips) }}</div>
                                        </v-progress-circular>
                                        <div>Deliveries / Trip</div>
                                    </v-col>
                                    <v-col class="text-center">
                                        <v-progress-circular
                                            size="75"
                                            :value="divide(currentJourney.totalDistance, currentJourney.totalTrips)">
                                            <div class="text-center">{{ divide(currentJourney.totalDistance, currentJourney.totalTrips) }}</div>
                                        </v-progress-circular>
                                        <div>Km / Trip</div>
                                    </v-col>
                                    <v-col class="text-center">
                                        <v-progress-circular
                                            size="75"
                                            :value="round(divide(currentJourney.totalMinutes, currentJourney.totalTrips) / 60, 2)">
                                            <div class="text-center">{{ round(divide(currentJourney.totalMinutes, currentJourney.totalTrips) / 60, 2) }}</div>
                                        </v-progress-circular>
                                        <div>Hrs / Trip</div>
                                    </v-col>
                                    <v-col class="text-center">
                                        <v-progress-circular
                                            size="75"
                                            :value="percent(divide(currentJourney.totalWeight, currentJourney.totalTrips), currentJourney.maxWeight)">
                                            <div class="text-center">{{ divide(currentJourney.totalWeight, currentJourney.totalTrips) }}</div>
                                        </v-progress-circular>
                                        <div>Kg / Trip</div>
                                    </v-col>
                                    <v-col class="text-center">
                                        <v-progress-circular
                                            size="75"
                                            :value="divide(divide(currentJourney.totalVolume, currentJourney.totalTrips), 1000000)">
                                            <div class="text-center">{{ divide(divide(currentJourney.totalVolume, currentJourney.totalTrips), 1000000) }}</div>
                                        </v-progress-circular>
                                        <div>m3 / Trip</div>
                                    </v-col>
                                    <v-col class="text-center">
                                        <v-progress-circular
                                            size="75"
                                            :value="percent(divide(currentJourney.totalPackages, currentJourney.totalTrips), currentJourney.maxPackages)">
                                            <div class="text-center">{{ divide(currentJourney.totalPackages, currentJourney.totalTrips) }}</div>
                                        </v-progress-circular>
                                        <div>Pkg / Trip</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="text-center">
                                        <!-- <v-progress-circular
                                            size="75"
                                            :value="percent(divide(currentJourney.totalDeliveries, currentJourney.totalTrips), currentJourney.maxDestinations)">
                                            <div class="text-center">{{ divide(currentJourney.totalDeliveries, currentJourney.totalTrips) }}</div>
                                        </v-progress-circular>
                                        <div>Deliveries / Trip</div> -->
                                    </v-col>
                                    <v-col class="text-center">
                                        <v-progress-circular
                                            size="75"
                                            :value="divide(currentJourney.totalDistance, currentJourney.totalDeliveries)">
                                            <div class="text-center">{{ divide(currentJourney.totalDistance, currentJourney.totalDeliveries) }}</div>
                                        </v-progress-circular>
                                        <div>Km / Delivery</div>
                                    </v-col>

                                    <v-col class="text-center">
                                        <v-progress-circular
                                            size="75"
                                            :value="divide(currentJourney.totalMinutes, currentJourney.totalDeliveries)">
                                            <div class="text-center">{{ divide(currentJourney.totalMinutes, currentJourney.totalDeliveries) }}</div>
                                        </v-progress-circular>
                                        <div>Mins / Delivery</div>
                                    </v-col>
                                    
                                    <v-col class="text-center">
                                        <v-progress-circular
                                            size="75"
                                            :value="percent(divide(currentJourney.totalWeight, currentJourney.totalDeliveries), 100)">
                                            <div class="text-center">{{ divide(currentJourney.totalWeight, currentJourney.totalDeliveries) }}</div>
                                        </v-progress-circular>
                                        <div>Kg / Delivery</div>
                                    </v-col>
                                    
                                    <v-col class="text-center">
                                        <v-progress-circular
                                            size="75"
                                            :value="divide(divide(currentJourney.totalVolume, currentJourney.totalDeliveries), 1000000)">
                                            <div class="text-center">{{ divide(divide(currentJourney.totalVolume, currentJourney.totalDeliveries), 1000000) }}</div>
                                        </v-progress-circular>
                                        <div>m3 / Trip</div>
                                    </v-col>

                                    <v-col class="text-center">
                                        <v-progress-circular
                                            size="75"
                                            :value="percent(divide(currentJourney.totalPackages, currentJourney.totalDeliveries), 100)">
                                            <div class="text-center">{{ divide(currentJourney.totalPackages, currentJourney.maxPackages) }}</div>
                                        </v-progress-circular>
                                        <div>Pkg / Delivery</div>
                                    </v-col>
                                    
                                </v-row>
                                <BT-Table
                                    :canRefresh="false"
                                    :canSelect="false"
                                    class="mt-4 pt-4"
                                    :headers="[
                                        { text: 'Date', value: 'startedOn', textFilter: 'toDayShortDate' },
                                        { text: 'Driver', value: 'driverUserID', display: true },
                                        { text: 'Deliveries', value: 'totalDeliveries', textFilter: 'toDisplayNumber' },
                                        { text: 'Km', value: 'totalDistance', textFilter: 'toDisplayNumber' },
                                        { text: 'Hours', value: 'totalMinutes', display: true },
                                        { text: 'Kg', value: 'totalWeight', textFilter: 'toDisplayNumber' }
                                    ]"
                                    hideActions
                                    :items="currentJourney.filteredStats">
                                    <template v-slot:totalMinutes="{ item }">
                                        {{ item.totalMinutes / 60 | toDisplayNumber }}
                                    </template>
                                    <template v-slot:driverUserID="{ item }">
                                        <BT-Entity
                                            navigation="users"
                                            :itemValue="item.driverUserID"
                                            itemText="userName" />
                                    </template>
                                </BT-Table>
                            </v-container>
                        </v-tab-item>
                        <v-tab-item>
                            <v-slide-x-transition>
                                <v-container v-if="currentDriverMode == 0">
                                    <v-row v-for="(driver, dInd) in currentJourney.driverStats" :key="dInd">
                                        <v-col class="d-flex align-center justify-center">
                                            <BT-Entity
                                                navigation="users"
                                                :itemValue="driver.id"
                                                itemText="userName" />
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="percent(divide(driver.totalDeliveries, driver.totalTrips), driver.maxDestinations)">
                                                <div class="text-center">{{ divide(driver.totalDeliveries, driver.totalTrips) }}</div>
                                            </v-progress-circular>
                                            <div>Deliveries / Trip</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="divide(driver.totalMinutes, driver.totalDeliveries)">
                                                <div class="text-center">{{ divide(driver.totalMinutes, driver.totalDeliveries) }}</div>
                                            </v-progress-circular>
                                            <div>Mins / Delivery</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="round(divide(driver.totalMinutes, driver.totalTrips) / 60, 2)">
                                                <div class="text-center">{{ round(divide(driver.totalMinutes, driver.totalTrips) / 60, 2) }}</div>
                                            </v-progress-circular>
                                            <div>Hrs / Trip</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="percent(divide(driver.totalWeight, driver.totalDeliveries), 100)">
                                                <div class="text-center">{{ divide(driver.totalWeight, driver.totalDeliveries) }}</div>
                                            </v-progress-circular>
                                            <div>Kg / Delivery</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="percent(divide(driver.totalWeight, driver.totalTrips), driver.maxWeight)">
                                                <div class="text-center">{{ divide(driver.totalWeight, driver.totalTrips) }}</div>
                                            </v-progress-circular>
                                            <div>Kg / Trip</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="percent(divide(driver.totalPackages, driver.totalDeliveries), 100)">
                                                <div class="text-center">{{ divide(driver.totalPackages, driver.maxPackages) }}</div>
                                            </v-progress-circular>
                                            <div>Pkg / Delivery</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="percent(divide(driver.totalPackages, driver.totalTrips), driver.maxPackages)">
                                                <div class="text-center">{{ divide(driver.totalPackages, driver.totalTrips) }}</div>
                                            </v-progress-circular>
                                            <div>Pkg / Trip</div>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-4" />
                                    <div class="d-flex align-center justify-center">
                                        <BT-Radar-Chart
                                            :labels="['Mins/Del', 'Kg/Del', 'm3/Del', 'Pck/Del', 'Km/Del']"
                                            :values="radarChartData"
                                            :width="500"
                                            :height="500" />
                                    </div>
                                </v-container>
                                <v-container v-else-if="currentDriverMode == 1">
                                    <v-row v-for="(driver, dInd) in currentJourney.driverStats" :key="dInd">
                                        <v-col class="d-flex align-center justify-center">
                                            <BT-Entity
                                                navigation="users"
                                                :itemValue="driver.id"
                                                itemText="userName" />
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="driver.totalTrips">
                                                <div class="text-center">{{ driver.totalTrips }}</div>
                                            </v-progress-circular>
                                            <div>Trips</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="driver.totalDeliveries">
                                                <div class="text-center">{{ driver.totalDeliveries }}</div>
                                            </v-progress-circular>
                                            <div>Deliveries</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="round(driver.totalMinutes / 60, 2)">
                                                <div class="text-center">{{ round(driver.totalMinutes / 60, 2) }}</div>
                                            </v-progress-circular>
                                            <div>Hours</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="driver.totalWeight">
                                                <div class="text-center">{{ driver.totalWeight }}</div>
                                            </v-progress-circular>
                                            <div>Kg</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="divide(driver.totalVolume, 1000000)">
                                                <div class="text-center">{{ divide(driver.totalVolume, 1000000) | toDisplayNumber }}</div>
                                            </v-progress-circular>
                                            <div>Volume (m3)</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="driver.totalPackages">
                                                <div class="text-center">{{ driver.totalPackages }}</div>
                                            </v-progress-circular>
                                            <div>Packages</div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container v-else-if="currentDriverMode == 2">
                                    <v-row>
                                        <v-col class="d-flex align-center justify-center">

                                        </v-col>
                                    </v-row>
                                    <v-row v-for="(driver, dInd) in currentJourney.driverStats" :key="dInd">
                                        <v-col class="d-flex align-center justify-center">
                                            <BT-Entity
                                                navigation="users"
                                                :itemValue="driver.id"
                                                itemText="userName" />
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="driver.totalTrips">
                                                <div class="text-center">{{ driver.totalTrips }}</div>
                                            </v-progress-circular>
                                            <div>Trips</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="driver.totalDeliveries">
                                                <div class="text-center">{{ driver.totalDeliveries }}</div>
                                            </v-progress-circular>
                                            <div>Deliveries</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="round(driver.totalMinutes / 60, 2)">
                                                <div class="text-center">{{ round(driver.totalMinutes / 60, 2) }}</div>
                                            </v-progress-circular>
                                            <div>Hours</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="driver.totalWeight">
                                                <div class="text-center">{{ driver.totalWeight }}</div>
                                            </v-progress-circular>
                                            <div>Kg</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="divide(driver.totalVolume, 1000000)">
                                                <div class="text-center">{{ divide(driver.totalVolume, 1000000) | toDisplayNumber }}</div>
                                            </v-progress-circular>
                                            <div>Volume (m3)</div>
                                        </v-col>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                size="60"
                                                :value="driver.totalPackages">
                                                <div class="text-center">{{ driver.totalPackages }}</div>
                                            </v-progress-circular>
                                            <div>Packages</div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-slide-x-transition>
                        </v-tab-item>
                        <v-tab-item>
                            <v-container>
                                <BT-Gantt-Chart
                                    chartStart="01/01/2000 0:00"
                                    chartEnd="01/02/2000 0:00"
                                    :dark="$vuetify.theme.dark"
                                    precision="hour"
                                    :rows="ganttData">
                                    <template v-slot:title>{{ currentJourney.journeyName }}</template>
                                    <template v-slot:rowLabel="{ data }">
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ data.data.startedOn | toDayShortDate }}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <BT-Entity
                                                        navigation="users"
                                                        :itemValue="data.data.driverUserID"
                                                        itemText="userName" />
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn v-if="!isLengthyArray(data.bars)" small @click="loadMovements(data)" icon>
                                                    <v-icon small>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </BT-Gantt-Chart>
                            </v-container>
                        </v-tab-item>
                    </v-tabs-items>
                </template>
            </BT-Blade>
        </template>
    </BT-Blades>
</template>

<script>

export default {
    name: 'Journey-Statistics',
    components: {
        //BTPieChart: () => import('~components/BT-Pie-Chart.vue'),
        BTGanttChart: () => import('~components/BT-Gantt-Chart.vue'),
        BTTable: () => import('~components/BT-Table.vue'),
        BTRadarChart: () => import('~components/BT-Radar-Chart.vue')
    },
    data: function() {
        return {
            currentDisplayMode: 0,
            currentDriverMode: 0,
            currentJourney: null,
            currentDate: null,
            currentFrom: null,
            currentTo: null,
            currentTimeSpan: 1,
            displayModes: [{ text: 'Numbers' }, { text: 'Graphs' }],
            driverModes: [{ text: 'Averages' }, { text: 'Totals' }, { text: 'Charts' }],
            loadingMsg: null,
            stepperState: 0,
            timeSpans: [
            { 
                    text: 'Today', 
                    from: this.$BlitzIt.auth.createRawTZ().startOf('day').plus({ minutes: -1 }).toUTC().toString(),
                    to: this.getTomorrow()
                },
                { 
                    text: 'This Week',
                    from: this.$BlitzIt.auth.createRawTZ().startOf('week').plus({ minutes: -1 }).toUTC().toString(),
                    to: this.$BlitzIt.auth.createRawTZ().endOf('week').toUTC().toString()
                },
                {
                    text: 'Last 7 Days',
                    from: this.$BlitzIt.auth.createRawTZ().startOf('day').plus({ days: -7, minutes: -1 }).toUTC().toString(),
                    to: this.getTomorrow()
                },
                { 
                    text: 'This Month',
                    from: this.$BlitzIt.auth.createRawTZ().startOf('month').plus({ minutes: -1 }).toUTC().toString(),
                    to: this.$BlitzIt.auth.createRawTZ().endOf('month').toUTC().toString()
                },
                { 
                    text: 'Last Month',
                    from: this.$BlitzIt.auth.createRawTZ().startOf('month').plus({ minutes: -1, months: -1 }).toUTC().toString(),
                    to: this.$BlitzIt.auth.createRawTZ().endOf('month').plus({ months: -1 }).toUTC().toString()
                },
                { 
                    text: 'Last 30 Days',
                    from: this.$BlitzIt.auth.createRawTZ().startOf('day').plus({ days: -30, minutes: -1 }).toUTC().toString(),
                    to: this.getTomorrow()
                },
                {
                    text: 'Custom Month'
                },
                // {
                //     text: 'Custom Dates'
                // }
            ]
        }
    },
    watch: {
        currentTimeSpan: function(val) {
            if (val != null) {
                this.currentDate = null;
                this.loadJourney(this.currentJourney, true)
            }
        }
    },
    computed: {
        ganttData() {
            if (this.currentJourney == null) {
                return null;
            }

            return this.currentJourney.allStats.map(x => {
                return {
                    label: this.$BlitzIt.auth.formTZ(x.startedOn, 'ccc dd LLL'),
                    bars: [], //[{ startOn: '01/01/2000 3:30', endOn: '01/01/2000 6:00' }],
                    data: x
                }
            });
        },
        radarChartData() {
            //[{ label: 'driver one', values: [5,8,3] },{ label: 'driver two', values: [2,9,3] }]"
            if (this.currentJourney == null) {
                return null;
            }

            return this.currentJourney.driverStats.map(x => {
                var r = [];

                r.push(Math.round(x.totalMinutes / x.totalDeliveries));
                r.push(Math.round(x.totalWeight / x.totalDeliveries));
                r.push(Math.round((x.totalVolume / 1000000) / x.totalDeliveries));
                r.push(Math.round(x.totalPackages / x.totalDeliveries));
                r.push(Math.round(x.totalDistance / x.totalDeliveries));

                return { label: x.id, values: r };
            })
        }
    },
    methods: {
        async loadJourneyData(j) {
            this.currentJourney = j;
            await this.loadJourney(j, false);
        },
        async getCustomMonth() {
            var d = await this.$selectDate({
                label: 'Select Month',
                type: 'month',
                fromNow: false
            });

            if (d != null) {
                var t = this.$BlitzIt.auth.formUTCDateTime(d);
                return {
                    from: t.plus({ seconds: -1 }).toString(),
                    to: t.plus({ months: 1 }).toString()
                };
            }

            return null;
        },
        async loadJourney(j, selectDate = false) {
            this.loadingMsg = 'Loading';
            if (j != null && this.currentTimeSpan != null) {
                var span = this.timeSpans[this.currentTimeSpan];
                var from = span.from;
                var to = span.to;

                //get calendar selection
                if (selectDate && span.text == 'Custom Month') {
                    var res = await this.getCustomMonth();

                    if (res != null) {
                        from = res.from;
                        to = res.to

                        this.currentFrom = from;
                        this.currentTo = to;
                        this.currentDate = this.$BlitzIt.auth.formRawTZ(from).plus({ seconds: 1 }).toFormat('MMM yyyy');
                    }
                    else {
                        this.loadingMsg = null;
                        return;
                    }
                }
                else if (span.text == 'Custom Month') {
                    from = this.currentFrom;
                    to = this.currentTo;
                }

                if ((from != null && to != null) && (j.from != from || j.to != to)) {
                    //load
                    j.from = from;
                    j.to = to;

                    var jRes = await this.$BlitzIt.store.getAll('journey-archives', {
                        templateID: j.id,
                        dateFrom: j.from,
                        dateTo: j.to
                    });

                    j.allStats = jRes.map(x => {
                        var from = this.$BlitzIt.auth.formUTCDateTime(x.startedOn);
                        var end = this.$BlitzIt.auth.formUTCDateTime(x.endedOn);

                        return Object.assign({}, {
                            ...x,
                            totalMinutes: end.diff(from, 'minutes').toObject().minutes,
                            totalDistance: (x.endingOdometre != null && x.startingOdometre != null) ? x.endingOdometre - x.startingOdometre : null
                        });
                    });

                    j.filteredStats = j.allStats;
                    j.totalDeliveries = j.allStats.sum(x => x.totalDeliveries);
                    j.totalDrivers = j.allStats.filter(x => x.driverUserID != null).distinct(x => x.driverUserID).length;
                    j.totalTrips = j.allStats.length;
                    j.totalWeight = j.allStats.sum(x => x.totalWeight);
                    j.totalVolume = j.allStats.sum(x => x.totalVolume);
                    j.totalMinutes = j.allStats.sum(x => x.totalMinutes);
                    j.totalPackages = j.allStats.sum(x => x.totalPackages);
                    j.totalDistance = j.allStats.sum(x => x.totalDistance || 0)
                    
                    j.driverStats = j.allStats.reduce((arr, x) => {
                        if (x.driverUserID != null) {
                            var existing = arr.find(y => y.id == x.driverUserID);

                            if (existing == null) {
                                existing = {
                                    id: x.driverUserID,
                                    totalDeliveries: 0,
                                    totalTrips: 0,
                                    totalWeight: 0,
                                    totalVolume: 0,
                                    totalMinutes: 0,
                                    totalPackages: 0,
                                    totalDistance: 0
                                }

                                arr.push(existing);
                            }

                            existing.totalDeliveries += x.totalDeliveries;
                            existing.totalTrips++;
                            existing.totalWeight += x.totalWeight;
                            existing.totalVolume += x.totalVolume;
                            existing.totalMinutes += x.totalMinutes;
                            existing.totalPackages += x.totalPackages;
                            existing.totalDistance += x.totalDistance;
                        }
                        
                        return arr;
                    }, []);
                }
            }
            this.loadingMsg = null;
        },
        async loadMovements(row) {
            var movRes = await this.$BlitzIt.store.getAll('delivery-archives', { journeyID: row.data.id });
            var results = movRes
                .orderByDate('deliveredOn')
                .map(x => {
                    return {
                        startOn:  this.$BlitzIt.auth.formRawTZ(x.deliveredOn).toFormat('01/01/2000 HH:mm'),
                        endOn: this.$BlitzIt.auth.formRawTZ(x.deliveredOn).plus({ minutes: 25 }).toFormat('01/01/2000 HH:mm'),
                        // startOn:  this.$BlitzIt.auth.formRawTZ(x.deliveredOn).toFormat('2000-01-01 HH:mm'),
                        // endOn: this.$BlitzIt.auth.formRawTZ(x.deliveredOn).plus({ minutes: 25 }).toFormat('2000-01-02 HH:mm'),
                        ganttBarConfig: {
                            id: x.id
                        }
                    }; 
                });

                // row.bars.push({
                //     startOn: '01/01/2000 12:00',
                //     endOn: '01/01/2000 15:00',
                //     ganttBarConfig: {
                //         id: '1'
                //     }
                // });
                results.forEach(r => {
                    row.bars.push(r);
                })

                this.$forceUpdate();
        },
        async pullJourneys(jList) {
            return jList.map(x => {
                return Object.assign({}, { 
                    ...x, 
                    allStats: [],
                    driverStats: [],
                    filteredStats: [],
                    from: null, //datetime
                    to: null, //datetime
                    totalDeliveries: 0,
                    totalDrivers: 0,
                    totalTrips: 0,
                    totalWeight: 0,
                    totalVolume: 0,
                    totalMinutes: 0,
                    totalPackages: 0,
                    totalDistance: 0
                 });
            });
        }
    }
}

</script>